'use strict';

angular.module('angus.controllers').controller('budgetRunRateCtrl', [
    '$scope', 'constantsService', '_', 'hierarchyService', 'fiscalYearService', 'dateCalculator', '$rootScope', 'datePickerService',
    'moment', '$http', '$q', 'yearMonthSelectService', 'restrictedAccessService', 'fluentRest',
    function($scope, constantsService, _, hierarchyService, fiscalYearService, dateCalculator, $rootScope, datePickerService,
             moment, $http, $q, yearMonthSelectService, restrictedAccessService, fluentRest) {

        $scope.periodIndicators = constantsService.budgetPeriodIndicators.enums;

        var divisions = [];
        var settingsBackup = null;
        var fiscalYearOfToday = null;
        //api/subscribers/5565c6e8af6871aca9df359c/budgets/years
        // $scope.yearChange = function(){
        //     addYearsToMonths();
        //     reloadWidget();
        // };

        function addYearsToMonths() {
            var year = $scope.fiscalYear;
            if(moment().month(months[0]).month() > 0 )
                year--;
            $scope.months = _.map(months, function(month, index) {
                var monthNumber = moment().month(month).month();
                if(monthNumber === 0 && index !== 0)
                    year++;
                return {
                    display : month, //+ ' - ' + year
                    value : monthNumber
                }
            });
        }

        var months;

        function loadWidget() {

            $scope.widget.noContentFlag = false;
            $scope.widget.instance.settings.selectedView = $scope.widget.instance.settings.selectedView || 1;

            divisions = hierarchyService.getLeafNodeEntityIds($rootScope.user.hierarchy.division.nodes, $scope.widget.instance.settings.hierarchyNodeId);
            var promise = $q
                .all([
                    fiscalYearService
                        .getFiscalYearMonthsOrdered()
                        .then(function(m) {
                            $scope.month = moment().month();
                            $scope.fiscalYear = fiscalYearOfToday = dateCalculator.getFiscalYear(moment(), moment().month(m[0]).month());

                            months = m;
                            addYearsToMonths();

                            $scope.yearChanged();
                        }),
                    restrictedAccessService
                        .getAccess()
                        .then(function(access) {
                            $scope.restrictedAccess = access.restrictedAccess;
                        })
                ])
                //api/subscribers/5565c6e8af6871aca9df359c/budgets/years

                .then(function(){
                    return queryPropaneVariance();
                }).then(function() {
                    // showLastMonthWithTransactions($scope.lastTrxDate);

                    return fluentRest
                        .api()
                        .subscribers($rootScope.user.subscriberId)
                        .budgets()
                        .years()
                        .get()
                        .then(function(years){
                            $scope.years = years;
                        })
                });

            return $scope.widget.promises.monitor(promise);
        }

        // displays previous month (with data) in widget month dropdown until current month has transactions to show
        function showLastMonthWithTransactions(actualsData) {

            if( (moment(actualsData).year() == '1900') ) {
                $scope.month = moment().month() - 1;
                $scope.months.pop();
                reloadWidget();
            }
            reloadWidget();
        }

        function reloadWidget() {
            $scope.widget.promises.monitor(queryPropaneVariance());
        }

        $scope.reloadWidget = reloadWidget;

        $scope.widget.setDefaultInitialization(loadWidget);

        $scope.yearChanged = function() {
            $scope.widget.noContentFlag = false;

            fiscalYearService
                .getFiscalYearMonthsOrdered()
                .then(function(months){
                    var startMonth   = moment().month(months[0]).month();
                    var currentMonth = moment().month();

                    $scope.months = _(months)
                        .map(function(m){
                            return {
                                value: moment().month(m).month(),
                                display : m
                            };
                        })
                        .filter(function(m){
                            return fiscalYearOfToday > $scope.fiscalYear || m.value <= currentMonth || m.value >= startMonth;
                        })
                        .value();

                    var lastMonth    = $scope.months[$scope.months.length - 1].value;

                    if(!$scope.month || ((fiscalYearOfToday === $scope.fiscalYear) &&  ($scope.month > lastMonth) && ($scope.month < startMonth)) ) {
                        $scope.month = currentMonth;
                        // queryPropaneVariance()
                        //     .then(function () {
                        //         showLastMonthWithTransactions($scope.lastTrxDate);
                        //     });
                    }
                    reloadWidget();
                });
        };

        $scope.fiscalYearChanged = function() {
            reloadWidget();
        };

        $scope.monthChanged = function() {
            reloadWidget();
        };

        function queryPropaneVariance() {

            var params = {
                h  : $scope.widget.instance.settings.hierarchyNodeId,
                hs : 1,
                wdo: $scope.widget.instance.settings.workingDaySubtraction ? $scope.widget.instance.settings.workingDaySubtraction.value : 0,
                pc : $scope.widget.instance.settings.productCategoryIds ? $scope.widget.instance.settings.productCategoryIds.value : null,
                tc : $scope.widget.instance.settings.tradeClassCategoryIds ? $scope.widget.instance.settings.tradeClassCategoryIds.value : null,
                ppc: $scope.widget.instance.settings.pricePlanCategoryIds ? $scope.widget.instance.settings.pricePlanCategoryIds.value : null,
                fy : $scope.fiscalYear
            };

            if($scope.periodIndicators.key != 'currentYear')
                params.m  = $scope.month;

            return fluentRest
                .api()
                .subscribers($rootScope.user.subscriberId)
                .budgets()
                .variance()
                .get(params)
                .then(function(response) {
                    if (!response ) {
                        $scope.widget.noContentFlag = true;
                    }
                    else {
                        console.log('response', response.aggregates);

                        $scope.periodBudget = response.aggregates.periodBudget.units;
                        $scope.budgetedGals = response.aggregates.budgeted.units.toFixed(0);
                        $scope.actualGals = response.aggregates.current.units.toFixed(0);

                        $scope.budget = response.aggregates ? response.aggregates : null;
                        $scope.view = response.aggregates ? $scope.widget.instance.settings.selectedView !== 2 ? response.aggregates.percentVariance : response.aggregates.variance : null;
                        if( (moment(response.endDate).year() < moment().year()) || (moment(response.endDate).month() < moment().month()) ) {
                            $scope.dataThroughDate = response.endDate;
                            $scope.widget.instance.settings.disableClosedMonthOffset = true;
                        } else {
                            $scope.dataThroughDate = response.dataThroughDate;
                            $scope.widget.instance.settings.disableClosedMonthOffset = false;
                        }
                        $scope.gallonsPerDayRequired = response.aggregates
                                                            ? response.aggregates.gallonsPerDayRequired.units > 0
                                                                ? response.aggregates.gallonsPerDayRequired.units
                                                                : 0
                                                            : null;
                        $scope.lastTrxDate = response.lastTrxDate;
                        $scope.widget.setDefaultActionModel({ month : $scope.month, year : $scope.fiscalYear });
                    }
                });
        }

        $scope.$on('widgetSettingsChanged', function() {
            reloadWidget();
        });
    }
]);
